
export default class AutoFocusInvalidField
{
    public static readonly selector = 'form';
    public static isRegistered = false;

    private element: HTMLElement;

    constructor(element: HTMLElement)
    {
        this.element = element;

        let firstInvalidField = this.element.querySelector('.form-control-danger');
        if (firstInvalidField != null)
        {
            firstInvalidField.scrollIntoView();
        }
    }

    public static register()
    {
        if (this.isRegistered)
        {
            return;
        }

        this.isRegistered = true;
        for (let element of document.querySelectorAll(this.selector))
        {
            new this(element as HTMLElement);
        }
    }
}