
export function findParentElement(element: HTMLElement, predicate: (element: HTMLElement) => boolean): HTMLElement | null
{
    while (true)
    {
        element = element.parentElement;
        if (element != null && predicate(element))
        {
            return element;
        }
        else if(element == null)
        {
            return null;
        }
    }
};


export function getAttribute(element: Element, name: string, defaultValue: any = undefined, postProcessor: (arg: string) => any = undefined)
{
    let result: string = undefined;
    if (element.hasAttribute(name))
    {
        result = element.getAttribute(name);
    }

    if (result == null || result.length == 0)
    {
        result = defaultValue;
    }
    else
    {
        if (postProcessor != null)
        {
            result = postProcessor(result);
        }
    }

    return result;
}

export function getAttributeAsInt(element: Element, name: string, defaultValue: any = undefined)
{
    return <any>getAttribute(element, name, defaultValue, parseInt) as number;
}

export function getAttributeAsFloat(element: Element, name: string, defaultValue: any = undefined)
{
    return <any>getAttribute(element, name, defaultValue, parseFloat) as number;
}

export function getAttributeAsJSON(element: Element, name: string, defaultValue: any = undefined)
{
    return <any>getAttribute(element, name, defaultValue, JSON.parse) as Object;
}

export function getAttributeAsArray(element: Element, name: string, defaultValue: any = undefined)
{
    return <any>getAttribute(element, name, defaultValue, JSON.parse) as Array<any>;
}

export function addOrRemoveClassName(element: HTMLElement, className: string, add: boolean)
{
    if (add)
        element.classList.add(className);
    else
        element.classList.remove(className);    
}