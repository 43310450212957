import { findParentElement } from "../helpers/dom";

const ATTRIBUTE = 'data-hook-tabbed-form-manager';
const SELECTOR = `[${ATTRIBUTE}]`;


export default class TabbedFormManager {
    element: HTMLElement;
    form: HTMLFormElement;
    formControls: HTMLInputElement[];

    constructor(element: HTMLElement) {
        this.element = element;
        this.form = this.element.querySelector('form');

        this.form.addEventListener('submit', this.onFormSubmit);

        this.formControls = [].slice.call(this.form.querySelectorAll('input, textarea, select')) as HTMLInputElement[];

        for (const control of this.formControls) {
            control.addEventListener('invalid', this.onFormControlInvalid);

            if (control.classList.contains('form-control-danger')) {
                this.reportFormControlInvalid(control);
            }
        }
    }

    onFormSubmit = (e: Event) => {
        
    };

    onFormControlInvalid = (e: Event) => {
        this.reportFormControlInvalid(e.target as HTMLInputElement);
    }

    reportFormControlInvalid(formControl: HTMLInputElement) {
        const tab = findParentElement(formControl, e => e.hasAttribute('data-is-tab-body'));
    
        (this.element.querySelector(`a[href="#${tab.id}"]`) as HTMLAnchorElement).click();
    }

    public static register() {
        for (const element of ([].slice.call(document.querySelectorAll(SELECTOR)) as HTMLElement[])) {
            new TabbedFormManager(element);
            element.removeAttribute(ATTRIBUTE);
        }
    }
}