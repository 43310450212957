// based on https://docs.djangoproject.com/en/2.0/ref/csrf/#ajax
export default class Cookie
{
    public static getValueByName(name: string)
    {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '')
        {
            let cookies = document.cookie.split(';');
            for (let cookie of cookies)
            {
                cookie = cookie.trim();
                if (cookie.substring(0, name.length + 1) === (name + '='))
                {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }

        return cookieValue;
    }
}