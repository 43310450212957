import BackendForm from "./backend-form";
import FormsetManager from "./formset-manager";

/**
 * Represents a form with formset and backend refresh functionality.
 */
export default class Form
{
    private form: HTMLFormElement;
    private isAjax: boolean;
    private formSetManagers: FormsetManager[];
    private backendForm: BackendForm;
    
    /**
     * Creates a new instance of a Form.
     * @param form The form.
     * @param isAjax Whether or not the target form was rendered with ajax.
     */
    constructor(form: HTMLFormElement, isAjax: boolean = false)
    {
        this.form = form;
        this.isAjax = isAjax;

        // Get from id from attribute, because if the form has a field name="id" then form.id will point to this field
        // instead of form's id-attribute
        const formId = this.form.getAttribute('id');
        this.formSetManagers = FormsetManager.registerByFormId(formId);
        this.backendForm = BackendForm.registerByFormId(formId);

        for(let formset of this.formSetManagers)
        {
            formset.on(FormsetManager.EVENTS.ROW_INSERTED, this.onFormsetRowInserted);
        }
    }

    /**
     * Registers a new form by its id attribute.
     * @param id The id of the form element.
     * @param isAjax  Whether or not the target form was rendered with ajax.
     */
    public static registerById(id: string, isAjax: boolean = false)
    {
        return new Form(document.getElementById(id) as HTMLFormElement, isAjax);
    }

    /**
     * Occurs when one of the formsets inserts a row.
     */
    private onFormsetRowInserted = (sender: FormsetManager) =>
    {
        this.backendForm.trigger(FormsetManager.EVENTS.ROW_INSERTED);
    };
}