export interface EventHandler
{
    (sender, ...args): void;
}

/** Abstract base class for subscrbing, unsubscribing, and triggering events. */
export abstract class EventSource
{
    protected eventHandlers: {[key: string]: EventHandler[]} = {};

    /**
     * Attatches an event handler to an event.
     * @param eventName Name of the even to listen to.
     * @param handler Event handler.
     */
    public on(eventName: string, handler: EventHandler): void
    {
        if (this.eventHandlers[eventName] === undefined)
        {
            this.eventHandlers[eventName] = [];
        }

        this.eventHandlers[eventName].push(handler);
    }

    /**
     * Removes an event handler from an event.
     * @param eventName Name of the event to unsubscribe from.
     * @param handler Event handler to unsubscribe.
     */
    public off(eventName: string, handler: EventHandler): void
    {
        let handlers = this.eventHandlers[eventName];

        if (handlers !== undefined)
        {
            for(let i = handlers.length - 1; i >= 0; i--)
            {
                if (handlers[i] === handler)
                {
                    handlers.splice(i, 1);
                }
            }
        }
    }

    /**
     * Triggers an event and alerts all of its listeners.
     * @param eventName Name of the event to trigger.
     * @param eventArgs Additional arguments to pass.
     */
    public trigger(eventName: string,  ...args): void
    {
        let handlers = this.eventHandlers[eventName];

        if (handlers !== undefined)
        {
            for(let handler of handlers)
            {
                handler(this, ...args);
            }
        }
    }
}
