export default class TableBuilderDisplayFilterDropDown {
    
    public static isRegistered = false;
    public static readonly selector = '.general-dropdown';
    public toggled:boolean;

    private element: HTMLElement;

    constructor(element: HTMLElement) {

        this.element = element

        const selectToggle = element.querySelector('.select-all-toggle');
        if (selectToggle) {
            this.toggled = this.element.hasAttribute('data-is-all-selected');
            selectToggle.addEventListener('click', this.onSelectAllToggle);
        }

        const filterInput = <HTMLInputElement> this.element.querySelector('#list-filter');
        if (filterInput)
            filterInput.onkeyup = this.onListFilterKeyUp;
    }
    
    public static register() {
        if (TableBuilderDisplayFilterDropDown.isRegistered) {
            return;
        }

        TableBuilderDisplayFilterDropDown.isRegistered = true;

        for (let element of document.querySelectorAll(TableBuilderDisplayFilterDropDown.selector)) {
            new TableBuilderDisplayFilterDropDown(element as HTMLElement);
        }
    }

    /**
     * Select toggle event listener.
     * When user press toggle button, all list items (checkbox) will be checked or unchecked
     * @param {event} KeyboardEvent
     */
    onSelectAllToggle = (event:Event) => {
        event.stopPropagation();
        this.toggleSelections(this.toggled);
        this.toggleLabels(this.toggled);
    }
    
    /**
     * Toggle drop down list item checkbox selections
     * @param {boolean} toggled Toggle all or not to toggle
     */
    toggleSelections = (toggled:boolean) => {
        const dropDownMenuItems = this.element.querySelectorAll('.dropdown-menu input[type=checkbox]');
        for (let menuItem of dropDownMenuItems) {
            let checkboxInput = <HTMLInputElement> menuItem;
            checkboxInput.checked = !toggled;
        }
        this.toggled = !toggled;
    }

    /**
     * Change toggle button label based toggle flag
     * @param {boolean} toggled Toggle all or not to toggle
     */
    toggleLabels = (toggled:boolean) => {
        let toggleButton = <HTMLButtonElement> this.element.querySelector('.select-all-toggle');
        const selectAllText = <HTMLSpanElement> toggleButton.querySelector('[data-select-all]');
        selectAllText.classList.toggle('hidden', !toggled)
        const unselectAllText = <HTMLSpanElement> toggleButton.querySelector('[data-clear-selection]');
        unselectAllText.classList.toggle('hidden', toggled)
    }

    /**
     * Filter input keyboard listener
     * @param {event} KeyboardEvent
     */
    onListFilterKeyUp = (event:KeyboardEvent) => {
        let filterInput = <HTMLInputElement> event.srcElement;
        let filter = filterInput.value.trim().toLowerCase();
        const dropDownMenuItems = this.element.querySelectorAll('.dropdown-menu .item');
        for (var i = 0; i < dropDownMenuItems.length; i++) {
            let menuItem = dropDownMenuItems[i] as HTMLSpanElement;
            let labelElement = <HTMLLabelElement> menuItem.querySelector('label')
            let text = labelElement.innerText.toLowerCase();
            if (text.indexOf(filter) > -1) {
                menuItem.style.display = '';   
            } else {
                menuItem.style.display = 'none';    
            }
        }
    }
}