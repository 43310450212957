import Form from './form';
import FormsetManager from "./form/formset-manager";
import BackendForm from "./form/backend-form";
import Ajax from './ajax/index';
import Url from './url/index';
import FileInputWidget from './form/file-input-widget';
import LazyModelSelectWidget from './form/lazy-model-select-widget';
import AutoFocusInvalidField from './form/auto-focus-invalid-field';
import TableBuilderDisplayFilterDropDown from './table-builder/display-filter-dropdown'
import SoftExpandToggle from './sidebar/soft-expand-toggle';
import './polyfill/array.find';
import './polyfill/custom-event';
import TabbedFormManager from './form/tabbed-form-manager';
import ActiveTabUrlSyncManager from './tabs/index';

export class App
{
    public static FormsetManagers: {[key: string]: FormsetManager[]} = {};
    public static BackendForms: {[key: string]: BackendForm} = {};
}

// Form registrations
FileInputWidget.register();
LazyModelSelectWidget.register();
AutoFocusInvalidField.register();
TabbedFormManager.register();

// Sidebar registrations
SoftExpandToggle.register();

// TableBuilder registrations
TableBuilderDisplayFilterDropDown.register();

ActiveTabUrlSyncManager.register();

export {FormsetManager, BackendForm, Form, Ajax, Url, FileInputWidget};
export const app = new App();