import Cookie from '../cookie/index';

export type AjaxMethod = 'get' | 'head' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace' | 'patch';

export class AjaxOfflineError extends Error {}
export class AjaxServerError extends Error {}

export default class Ajax
{
    public static send(method: AjaxMethod, url: string, data: any = undefined, includeCsrfToken = true, additionalHeaders?: {[key: string]: string}): Promise<string>
    {
        if (!navigator.onLine)
        {
            return Promise.reject(new AjaxOfflineError('Network connection is offline.'));
        }

        includeCsrfToken = includeCsrfToken != null ? includeCsrfToken : false;
        data = data != null ? data : {};
        let request = new XMLHttpRequest();

        return new Promise((resolve, reject) =>
        {
            request.onreadystatechange = () =>
            {
                if(request.readyState === 4)
                {
                    if (request.status === 200)
                    {
                        resolve(request.responseText);
                    }
                    else
                    {
                        reject(new AjaxServerError(`Unexpected status: ${request.status} (expected: 200)`));
                    }
                }
            };

            request.open(method, url, true);
            let addContentTypeHeader = true;
            // If we are sending Formdata, let XmlHttpRequest set the header automatically.
            // @TODO: Next version of Ajax request helper should probably use a builder pattern which allows
            // setting headers, etc.
            if (data instanceof FormData)
            {
                addContentTypeHeader = false;
            }

            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            if (addContentTypeHeader)
            {
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            }
            
            if (includeCsrfToken)
            {
                request.setRequestHeader('X-CSRFToken', Cookie.getValueByName('csrftoken'));
            }
            request.timeout = 10000;

            if (method === 'post')
            {
                request.send(data);
            }
            else
            {
                request.send();
            }
        });
    }

    public static get(url: string): Promise<string>
    {
        return this.send('get', url);
    }

    public static post(url: string, data: any = undefined, includeCsrfToken = false): Promise<string>
    {
        data = typeof data === 'string' ? data : JSON.stringify(data);
        return this.send('post', url, data, includeCsrfToken);
    }

    public static json<T = Object>(url: string, data: any = undefined, includeCsrfToken = false, method: AjaxMethod = 'post'): Promise<T>
    {
        return this.send(method, url, data, includeCsrfToken).then((data) =>
        {
            return JSON.parse(data);
        });
    }
    
    public static submit(form: HTMLFormElement, method: AjaxMethod = 'post', includeCsrfToken = true): Promise<string>
    {
        return this.send(method, form.action, new FormData(form), includeCsrfToken);
    }
};