const ATTRIBUTE = 'data-sync-tab';
const SELECTOR = `[${ATTRIBUTE}]`;

/**
 * http://stackoverflow.com/a/10997390/11236
 */
function updateURLParameter(url, param, paramVal){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}

export default class ActiveTabUrlSyncManager {
    element: HTMLElement;
    parameterName: string;
    input: HTMLInputElement;

    constructor(element: HTMLElement) {
        this.element = element;
        this.parameterName = element.getAttribute('data-to-url');
        this.input = document.querySelector(element.getAttribute('data-to-input'));

        for (const tabAnchor of this.element.querySelectorAll('a[data-toggle="tab"]') as NodeListOf<HTMLAnchorElement>) {
            tabAnchor.addEventListener('click', (e) => {
                const tabId = tabAnchor.href.split('#')[1];
                console.log(tabId)
                const separator = location.href.indexOf('?') != -1 ? '&' : '?';
                
                const url = updateURLParameter(location.href, this.parameterName, tabId);
                history.replaceState({}, document.title, url);

                if (this.input) {
                    this.input.value = tabId;
                }
            });
        }
    }

    public static register() {
        for (const element of ([].slice.call(document.querySelectorAll(SELECTOR)) as HTMLElement[])) {
            new ActiveTabUrlSyncManager(element);
            element.removeAttribute(ATTRIBUTE);
        }
    }
}