import Ajax from "../ajax/index";
import { App } from "../core-next";
import { EventSource } from "../event-source/index";
import FormsetManager from "./formset-manager";
import LazyModelSelectWidget from "./lazy-model-select-widget";

/**
 * Represents a form with backend refreshing functionalities.
 */
export default class BackendForm extends EventSource
{
    private form: HTMLFormElement;
    private isAjax: boolean;
    private registeredFields: HTMLElement[];

    public static EVENTS = {
        REFRESHED: 'refreshed',
    };

    /**
     * Creates a new instance of BackendForm.
     * @param form The form.
     * @param isAjax  Whether or not the target form was rendered with ajax.
     */
    constructor(form: HTMLFormElement, isAjax: boolean = false)
    {
        super();

        this.form = form;
        this.isAjax = isAjax;
        this.registeredFields = [];
        this.run();
        this.bindEvents();

        App.BackendForms[this.form.id] = this;
    }

    /**
     * Registers a new form by its id attribute.
     * @param id The id of the form element.
     * @param isAjax  Whether or not the target form was rendered with ajax.
     */
    public static registerByFormId(id: string, isAjax: boolean = true)
    {
        return new BackendForm(document.getElementById(id) as HTMLFormElement, isAjax);    
    }

    /**
     * Whether or not a field is already registered.
     * @param field The field to check.
     */
    private isFieldRegistered(field: HTMLInputElement)
    {
        return this.registeredFields.indexOf(field) !== -1;
    }

    /** Find all non-registered fields and registers them. */
    public run()
    {
        this.init();
    }

    /** Binds all the events. */
    private bindEvents()
    {
        this.on(FormsetManager.EVENTS.ROW_INSERTED, this.onFormsetRowInserted);
    }

    /** Occurs when a formset inserts a row. */
    private onFormsetRowInserted = (sender: BackendForm) =>
    {
        this.init();
    };

    private init()
    {
        let dependencyFields = this.form.querySelectorAll('[data-depends-on]') as NodeListOf<HTMLInputElement>;
        let autoRefreshFields = this.form.querySelectorAll('[data-auto-refresh-form]') as NodeListOf<HTMLInputElement>;

        let skipSourceFieldNames = [];

        for(let dependencyField of dependencyFields)
        {
            // Get the name of the source field
            var sourceFieldName = dependencyField.getAttribute('data-depends-on');

            // If it's already processed, skip it (multi-dependency)
            if (skipSourceFieldNames.indexOf(sourceFieldName) !== -1 || this.isFieldRegistered(dependencyField))
            {
                continue;
            }

            // Check for multi-dependency
            var multiDependencyFields = this.form.querySelectorAll(`[data-depends-on="${sourceFieldName}"]`) as NodeListOf<HTMLInputElement>;
            if (multiDependencyFields.length > 1)
            {
                skipSourceFieldNames.push(sourceFieldName);
                this.registerMultiDependencyField(multiDependencyFields, sourceFieldName);
            }
            else
            {
                this.registerDependencyField(dependencyField, sourceFieldName);
            }

            this.registeredFields.push(dependencyField);
        }

        // Register auto-reload fields
        for (let autoRefreshField of autoRefreshFields)
        {
            if (this.isFieldRegistered(autoRefreshField))
            {
                continue;
            }

            this.registerAutoRefreshField(autoRefreshField);
            this.registeredFields.push(autoRefreshField);
        }
    }

    private refreshForm()
    {
        // Keep track of elements that have their value marked as persisting through backend form refresh
        let persistingValueElements: HTMLInputElement[] = Array.prototype.slice.call(this.form.querySelectorAll('[data-backend-form-refresh-keep-value]'));
        // Keep track of the id of the element that was focused before refreshing the form
        let elementIdFocusedBeforeRefresh = document.activeElement.id;

        return Ajax.submit(this.form).then((result) =>
        {
            let formWrapper = this.form.parentElement;
            formWrapper.innerHTML = result;

            if (this.isAjax)
            {
                // Executes the script. setting .innerHTML does not run <script> tags!
                eval(formWrapper.querySelector('#registerComponents').textContent);
            }
            
            // Restore values that were marked as persisting
            for(let persistingElement of formWrapper.querySelectorAll('[data-backend-form-refresh-keep-value]') as NodeListOf<HTMLInputElement>)
            {
                let original = persistingValueElements.find(o => o.id == persistingElement.id);
                if (original != null)
                {
                    persistingElement.value = original.value;
                }
            }
            
            // Restore focus
            document.getElementById(elementIdFocusedBeforeRefresh).focus();
            // trigger event
            this.trigger(BackendForm.EVENTS.REFRESHED);
            // Register any lazy model select widget
            // Registration should probably be trigged via some kind of event bus but this does the job for now.
            LazyModelSelectWidget.register();
        });
    }
    
    private registerAutoRefreshField(field: HTMLElement)
    {
        field.addEventListener('change', this.onAutoRefreshFieldChanged);
    }

    private onAutoRefreshFieldChanged = (e: Event) =>
    {
        this.refreshForm();
    };

    /**
     * Sets a source field enabled state. Returns true if the field state can be safely modified.
     * @param field 
     * @param locked 
     */
    private setSourceFieldLocked(field: HTMLInputElement, locked = true)
    {
        // Field is already reaonly or disabled, let's not touch it
        if (field.hasAttribute('readonly') || field.classList.contains('disabled'))
        {
            return false;
        }

        if (locked)
        {
            field.setAttribute('readonly', '');
        }
        else
        {
            field.removeAttribute('readonly');
        }

        return true;
    }
    private registerDependencyField = (field: HTMLInputElement, sourceFieldName: string) =>
    {
        let sourceField = this.form.querySelector('#id_' + sourceFieldName) as HTMLInputElement;
        // Create a wrapper handler so that we can pass the dependency field as well
        let handler = (e: Event) => this.onDependencySourceFieldChanged(sourceField, field, e);
        sourceField.addEventListener('change', handler);
    };

    private registerMultiDependencyField(fields: NodeListOf<HTMLInputElement>, sourceFieldName: string)
    {
        let sourceField = this.form.querySelector(`#id_${sourceFieldName}`) as HTMLInputElement;
        // Create a wrapper handler so that we can pass the dependency fields as well
        let handler = (e: Event) => this.onMultiDependencyFieldChanged(sourceField, fields, e);
        sourceField.addEventListener('change', handler);
    }

    /**
     * When ever a dependency source field changes, the form refreshes.
     */
    private onDependencySourceFieldChanged(sourceField: HTMLInputElement, dependencyField: HTMLInputElement, e: Event)
    {
        this.setSourceFieldLocked(sourceField, true);
        dependencyField.disabled = true;
        this.refreshForm().then(() =>
        {
            dependencyField.disabled = false;
            this.setSourceFieldLocked(sourceField, false);
        });
    };


    private onMultiDependencyFieldChanged(sourceField: HTMLInputElement, dependencyFields: NodeListOf<HTMLInputElement>, e: Event)
    {
        this.setSourceFieldLocked(sourceField, true);
        let fieldsToReEnable = [];
        // Add all non-disabled fields to an array so that we won't accidentally enable disabled fields later
        for (let field of dependencyFields)
        {
            if (!field.disabled)
            {
                field.disabled = true;
                fieldsToReEnable.push(fieldsToReEnable);
            }
        }

        this.refreshForm().then(() =>
        {
            for (let field of fieldsToReEnable)
            {
                field.disabled = false;
            }
            this.setSourceFieldLocked(sourceField, false);
        });
    }
}