import {findParentElement} from '../helpers/dom';
import { App, FileInputWidget } from '../core-next';
import { EventSource } from '../event-source/index';
import LazyModelSelectWidget from './lazy-model-select-widget';

/**
 * Represents a formset with insertion and deletion functionalities.
 */
export default class FormsetManager extends EventSource
{
    private form: HTMLFormElement;
    private formset: HTMLElement;
    private emptyTemplateElement: HTMLElement;

    private insertRowButton: HTMLButtonElement;
    private formsetRowContainer: HTMLElement;
    private insertBeforeReference: HTMLElement;
    private totalFormsElement: HTMLInputElement;

    private isAjax: boolean;
    private prefix: string;
    private emptyTemplate: string;
    private deleteButtonSelector: string;

    private __formCount: number = 0;
    private get formCount(): number { return this.__formCount; }
    private set formCount(value: number)
    {
        this.__formCount = value;
        this.totalFormsElement.setAttribute('value', this.__formCount.toString());
    }

    private actualFormCount = 0;
    
    public static EVENTS = {
        ROW_INSERTED: 'rowInserted',
        AFTER_ROW_INSERTED: 'afterRowInserted',
        ROW_REMOVED: 'rowRemoved',
        INSERT_EMPTY_ROW: 'insertEmptyRow',
        INSERT_ROW: 'insertRow',
        REMOVE_ROW: 'removeRow',
        GET_MANAGER: 'getManager',
        READY: 'ready'
    };
    
    /**
     * Creates a new instance of FormsetManager.
     * @param formset The formset.
     * @param isAjax  Whether or not the target form was rendered with ajax.
     */
    constructor(formset: HTMLElement, isAjax: boolean = false) 
    {
        super();

        this.formset = formset;
        this.isAjax = isAjax;
        this.run();
        this.bindEvents();
        
        if (this.insertRowButton) {
            this.insertRowButton.disabled = false;
        }

        if (App.FormsetManagers[this.form.id] == null)
        {
            App.FormsetManagers[this.form.id] = [];
        }

        App.FormsetManagers[this.form.id].push(this);
        this.dispatch(FormsetManager.EVENTS.READY, {manager: this});
    }

    /**
     * Registers new formsets by form id attribute.
     * @param id The id of the form element.
     * @param isAjax Whether or not the target form was rendered with ajax.
     */
    public static registerByFormId(id: string, isAjax: boolean = false)
    {
        let result = [];
        let form = <HTMLFormElement>document.getElementById(id);
        let formsets = <NodeListOf<HTMLElement>>form.querySelectorAll('[data-is-formset]');

        for (let formset of formsets)
        {
            result.push(new FormsetManager(formset, isAjax));
        }

        return result;
    }

    private run()
    {
        this.init();
    }

    private init()
    {
        this.emptyTemplateElement = this.formset.querySelector('[data-is-empty-template]');
        this.form = findParentElement(this.formset, element => element.hasAttribute('data-is-formset-form')) as HTMLFormElement;

        const prefix = this.formset.getAttribute('data-prefix');
        this.prefix = prefix != null ? prefix : 'form';
        if (this.prefix.length > 0) {
            this.prefix = this.prefix + '-';
        }
        this.emptyTemplate = this.emptyTemplateElement.innerHTML;
        this.insertRowButton = this.formset.querySelector('[data-is-add-more-button]');
        this.formsetRowContainer = this.formset.querySelector('[data-is-formset-row-container]');
        this.insertBeforeReference = this.formset.querySelector('[data-is-append-before-reference]');
        this.deleteButtonSelector = '.formsetItemDelete';
        this.totalFormsElement = this.formset.querySelector(`#id_${this.prefix}TOTAL_FORMS`);
        this.formCount = parseInt(this.totalFormsElement.getAttribute('value'));
        this.actualFormCount = this.formCount;
    }

    private bindEvents()
    {
        if (this.insertRowButton != null) 
        {
            this.insertRowButton.addEventListener('click', this.onInsertRowButtonClick);
        }
        this.form.addEventListener('submit', this.onFormSubmit);

        this.formset.addEventListener(FormsetManager.EVENTS.INSERT_EMPTY_ROW, () =>
        {
            this.onInsertRowButtonClick(null);
        });

        this.formset.addEventListener(FormsetManager.EVENTS.INSERT_ROW, (e: CustomEvent) =>
        {
            this.insertRow(e.detail);
        });

        this.formset.addEventListener(FormsetManager.EVENTS.REMOVE_ROW, (e: CustomEvent) =>
        {
            this.removeRow(e.detail.index);
        });

        this.formset.addEventListener(FormsetManager.EVENTS.GET_MANAGER, (e: CustomEvent) => {
            e.detail.callback(this);
        });

        // Add listeners to existing delete buttons
        const existingDeleteButtons = this.formset.querySelectorAll(this.deleteButtonSelector);

        for(const button of existingDeleteButtons) {
            button.addEventListener('click', this.onDeleteRowButtonClick);
        }
    }

    //#region event handlers
    private onFormSubmit = (e: Event) =>
    {
    };
    private onInsertRowButtonClick = (e: MouseEvent) =>
    {
        this.insertRow();
    };
    private onDeleteRowButtonClick = (e: MouseEvent) =>
    {
        let rows = this.formsetRowContainer.querySelectorAll('[data-is-formset-row]');
        let isFound = false;
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            if (row.contains(e.currentTarget as HTMLButtonElement)) {
                this.removeRow(i);
                isFound = true;
                break;
            }
        }
    };

    // The way of figuring 
    private removeRow(index: number)
    {
        let row = this.formsetRowContainer.querySelectorAll('[data-is-formset-row]')[index] as HTMLElement;
        // support declaration of multiple deletion inputs (Crispy forms version for python 3)
        for (const deleteInput of this.formsetRowContainer.querySelectorAll(`[name="${this.prefix}${index}-DELETE"]`) as NodeListOf<HTMLInputElement>) {
            if (deleteInput.type === 'checkbox') {
                deleteInput.setAttribute('checked', '');
            } else if (deleteInput.type === 'hidden') {
                deleteInput.setAttribute('value', '1');
            } else {
                throw new Error(`[Formset Manager] Unsupported input type of DELETE field: "${deleteInput.type}"`);
            }
        }
        
        row.classList.add('hidden');
        this.actualFormCount--;

        this.dispatch(FormsetManager.EVENTS.ROW_REMOVED, {row: row, manager: this});
    }

    private renderRowTemplate(index: number)
    {
        return this.emptyTemplate.replace(/__prefix__/g, index.toString())
    }

    private insertRow(data?: {[key: string]: any})
    {
        let newRow: HTMLElement = document.createElement('div');
        newRow.innerHTML = this.renderRowTemplate(this.formCount);
        if (newRow.childElementCount == 1) {
            newRow = newRow.firstElementChild as HTMLElement;
        }
        if (data != null)
        {
            for (let key in data)
            {
                let value = data[key];
                let selector = `[name="${this.prefix}${this.formCount}-${key}"]`;
                let field = newRow.querySelector(selector) as HTMLInputElement;
                if (field != null)
                {
                    field.value = value;
                    if (field.type == 'checkbox') {
                        field.checked = value;
                    }
                }
            }
        }
        this.formsetRowContainer.insertBefore(newRow, this.insertBeforeReference);
        const deleteButton = newRow.querySelector(this.deleteButtonSelector);
        if (deleteButton) {
            deleteButton.addEventListener('click', this.onDeleteRowButtonClick);;
        }
        (newRow.querySelector('input:first-child') as HTMLElement).focus();
        this.formCount++;
        this.actualFormCount++;
        const rowInsertedEventData = {
            row: newRow, data: data, manager: this
        };
        // Event triggered before registering lazy model choice fields and other widgets
        this.dispatch(FormsetManager.EVENTS.ROW_INSERTED, rowInsertedEventData);
        LazyModelSelectWidget.register();
        (window as any).__registerBoostrapDateWidgets();
        // In some cases we want to do stuff after lazy model choice fields and other widgets
        // have registered, i.e. command them.
        this.dispatch(FormsetManager.EVENTS.AFTER_ROW_INSERTED, rowInsertedEventData);
        FileInputWidget.register();
    }

    private dispatch(eventName: string, data: any) {
        this.trigger(eventName);
        this.formset.dispatchEvent(new CustomEvent(eventName, {detail: data}));
    }
}
