
// https://developer.mozilla.org/fi/docs/Web/API/CustomEvent/CustomEvent
export default (function () {
    // @ts-ignore
    if ( typeof window.CustomEvent === "function" ) return false;
  
    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
     }
  
    // @ts-ignore
    CustomEvent.prototype = window.Event.prototype;
  
    // @ts-ignore
    window.CustomEvent = CustomEvent;
})();