import KeyCode from "../enum/keycode";

export default class LazyModelSelectWidget {
    public static readonly selector = '[data-hook-lazy-model-select-field]';

    public static Events = {
        ItemSelected: 'itemSelected',
        // Deprecation
        get backendSearch()
        {
            throw new Error('LazyModelSelectWidget.Events.backendSearch has been moved to LazyModelSelectWidget.CommandEvents.BackendSearch')   
        },
        OptionsUpdated: 'optionsUpdated',
        InitialOptionSelected: 'initialOptionSelected',
        Ready: 'ready',
    };

    public static CommandEvents = {
        TriggerItemSelected: 'triggerItemSelected',
        BackendSearch:  'backendSearch',
        Clear: 'clear'
    };

    private element: HTMLInputElement;
    private uuid: string;
    private searchPlaceholder: string;
    private additionalFilters: {[key: string]: string};
    private extraColumnMap: {[key: string]: string};
    private autoFetchFirstBatch: boolean;
    private autoSelectOnlyOption: boolean;
    private allowEmptyAdditionalFilterValue: boolean;
    private initial: Option;

    private wrapper: HTMLElement;
    private searchField: HTMLInputElement;
    private changeButton: HTMLButtonElement;
    private clearButton: HTMLButtonElement;
    private optionsList: HTMLElement;
    private searchCooldownTimeout: number;

    //#region properties
    private searchText = '';
    private _isSearchMode = true;
    private get isSearchMode() { return this._isSearchMode; }
    private set isSearchMode(value: boolean) {
        this._isSearchMode = value;
        this.wrapper.classList[value ? 'remove' : 'add']('has-value-selected');

        if (value) {
            this.searchField.readOnly = false;
            this.searchField.value = this.searchText;
            this.searchField.placeholder = this.searchPlaceholder;
        } else {
            this.searchField.readOnly = true;
            this.searchField.placeholder = null;
            this.showOptions = false;
        }
    }

    private _isSearchFieldFocused = false;
    private get isSearchFieldFocused() { return this._isSearchFieldFocused; }
    private set isSearchFieldFocused(value: boolean) {
        this._isSearchFieldFocused = value;
        this.wrapper.classList[value ? 'add' : 'remove']('is-search-active');
    }

    private _value: string;
    private get value() { return this._value; }
    private set value(value: string) {
        this._value = value;
        this.element.value = value;
    }

    private _isLoading = false;
    private get isLoading() { return this._isLoading; }
    private set isLoading(value: boolean) {
        this._isLoading = value;
        this.wrapper.classList[value ? 'add': 'remove']('is-loading');
    }

    private _options: OptionsData;
    private get options() { return this._options; }
    private set options(value: OptionsData) {
        this._options = value;

        while(this.optionsList.firstChild) {
            this.optionsList.removeChild(this.optionsList.firstChild);
        }

        // Create a close to avoid having to map
        let self = this;
        function addOptionEventHandlers(element: HTMLElement, option: Option) {
            element.addEventListener('mousedown', (e) => {
                self.selectOption(option);
            });
        }

        for (let i = 0; i < value.options.length; i++) {
            let option = value.options[i];
            let element = document.createElement('div');
            option.element = element;
            this.optionsList.appendChild(element);

            element.classList.add('option');
            element.innerText = option.label;
            element.setAttribute('data-index', i.toString());
            addOptionEventHandlers(element, option);
            
            if (option == value.options[0] && false) {
                element.classList.add('is-selected');
                this.selectedOption = option;
            }
        }

        if (value.options.length === 0) {
            let element = document.createElement('div');
            element.classList.add('option-empty');
            element.innerText = this.element.getAttribute(Attribute.NoResultsMessage);
            this.optionsList.appendChild(element);
        } else if (value.options.length === 1 && this.autoSelectOnlyOption === true) {
            setTimeout(() => {
                this.selectOption(value.options[0]);
            }, 0);
        }

        let optionsFetchedEvent = new CustomEvent(LazyModelSelectWidget.Events.OptionsUpdated, {
            detail: {
                current: this.selectedOption
            }
        });
        this.element.dispatchEvent(optionsFetchedEvent);
    }

    private _selectedOption: Option;
    private get selectedOption() { return this._selectedOption; }

    private set selectedOption(option: Option) {
        this._selectedOption = option;
        if (option == null) {
            this.value = null;
            this.searchField.value = '';
            // back to search mode if empty
            this.isSearchMode = true;
        } else {
            this.value = option.value;
            this.unSelectAllOptions();
            option.element.classList.add('is-selected');
            this.ensureOptionVisible(option);

            if (!this.isSearchMode) {
                this.searchField.value = option.label;
            }
            this.applyExtraColumnMaps(option);
        }
    }

    private _showOptions = false;
    private get showOptions() { return this._showOptions; }
    private set showOptions(value: boolean) {
        this._showOptions = value;
        this.wrapper.classList[value ? 'add' : 'remove']('has-options-visible');

        if (value && this.selectedOption != null) {
            this.ensureOptionVisible(this.selectedOption);
        }
    }
    //#endregion

    constructor(element: HTMLInputElement) {
        // initial variables
        this.element = element;
        this.uuid = this.element.getAttribute(Attribute.UUID);
        this.additionalFilters = JSON.parse(this.element.getAttribute(Attribute.AdditionalFilters));
        this.autoFetchFirstBatch = this.element.hasAttribute(Attribute.AutoFetchFirstBatch);
        this.autoSelectOnlyOption = this.element.hasAttribute(Attribute.AutoSelectOnlyOption);
        this.allowEmptyAdditionalFilterValue = this.element.hasAttribute(Attribute.AllowEmptyAdditionalFilterValue);
        this.extraColumnMap = JSON.parse(this.element.getAttribute(Attribute.ExtraColumnMap));
        this.searchPlaceholder = this.element.getAttribute(Attribute.SearchPlaceholder);
        // Initially selected option
        if (element.hasAttribute(Attribute.Initial))
        {
            this.initial = JSON.parse(element.getAttribute(Attribute.Initial));
        }

        // Other elements
        this.optionsList = document.getElementById(this.element.getAttribute(Attribute.OptionsListId));
        this.wrapper = document.getElementById(this.element.getAttribute(Attribute.WrapperId));
        this.searchField = document.getElementById(this.element.getAttribute(Attribute.SearchFieldId)) as HTMLInputElement;
        this.changeButton = document.getElementById(this.element.getAttribute(Attribute.ChangeButtonId)) as HTMLButtonElement;
        this.clearButton = document.getElementById(this.element.getAttribute(Attribute.ClearButtonId)) as HTMLButtonElement;

        // Search field attributes
        this.searchText = this.searchField.getAttribute(Attribute.InitialSearchText);
        console.log({searchText: this.searchText})
        
        // Events
        this.changeButton.addEventListener('click', this.onChangeButtonClick);
        this.clearButton.addEventListener('click', this.onClearButtonClick);
        this.searchField.addEventListener('keyup', this.onSearchFieldChange);
        this.searchField.addEventListener('keyup', this.onSearchFieldKeyUp);
        this.searchField.addEventListener('keydown', this.onSearchFieldKeyDown);
        this.searchField.addEventListener('input', this.onSearchFieldChange);
        this.searchField.addEventListener('focus', this.onSearchFieldFocus);

        this.searchField.addEventListener('blur', this.onSearchFieldBlur);
        this.searchField.addEventListener('click', this.onSearchFieldClick);
        
        this.element.addEventListener(LazyModelSelectWidget.CommandEvents.BackendSearch, this.onBackendSearch);
        this.element.addEventListener(LazyModelSelectWidget.CommandEvents.TriggerItemSelected, this.onTriggerItemSelected);
        this.element.addEventListener(LazyModelSelectWidget.CommandEvents.Clear, this.onClearEvent);

        // Initial options data
        let options = {
            success: true,
            options: [],
            status: '',
            is_fallback_option: false
        };

        // Initial state
        this.isLoading = true;
        this.value = this.element.value;
        if (this.initial != null) {
            options.options.push(this.initial);
            this.isSearchMode = false;
        }
        else {
            this.isSearchMode = true;
            this.showOptions = false;

            if (this.value != null && this.value.length > 0 && this.initial == null)
            {
                this.isLoading = true;
                this.backendSearch({
                    includeSelected: true,
                    showOptions: false,
                    onlySelected: true
                }).then(() =>
                {
                    
                });
            }
        }
        
        this.options = options;
        
        if (this.initial != null) {
            this.selectOption(this.initial, false);

            let event = new CustomEvent(LazyModelSelectWidget.Events.InitialOptionSelected, {
                detail: {
                    current: this.selectedOption
                }
            });

            this.element.dispatchEvent(event);
        }

        if (this.autoFetchFirstBatch) {
            this.backendSearch({
                showOptions: false,
                useBlankSearch: true,
                includeSelected: true
            }).then(() => {
                this.onReady();
            });
        } else {
            this.onReady();
        }
    }

    private onReady() {
        const isReadonly = this.searchField.getAttribute('data-is-readonly') === '1';
        const isDisabled = this.searchField.getAttribute('data-is-disabled') === '1';

        this.searchField.disabled = isDisabled;
        this.changeButton.disabled = isReadonly || isDisabled;
        this.clearButton.disabled = isReadonly || isDisabled;
        this.isLoading = false;
        
        this.hookAdditionalFilterSourceFields();
        this.element.dispatchEvent(
            new CustomEvent(LazyModelSelectWidget.Events.Ready),
        );
    }

    //#region event handlers

    /** Executed when the change button is clicked. */
    private onChangeButtonClick = (e: MouseEvent) => {
        this.focus();
    };

    private onClearButtonClick = (e: MouseEvent) => {
        this.clear();
        this.focus();
    };

    /** Executed when the search field value changes. */
    private onSearchFieldChange = (e: Event) => {            
        let searchTextChanged = this.searchText != this.searchField.value;

        if (searchTextChanged) {
            this.searchText = this.searchField.value;
            this.isLoading = true;
            window.clearTimeout(this.searchCooldownTimeout);
            
            this.searchCooldownTimeout = window.setTimeout(() => {
                if (searchTextChanged) {
                    this.backendSearch().then(() => {
                        this.isLoading = false;
                    });
                }
            }, 500);
        }
    };

    /** Used to prevent default on Enter and arrow up & down keys for the search field. */
    private onSearchFieldKeyDown = (e: KeyboardEvent) =>
    {
        switch(e.keyCode)
        {
            case KeyCode.Enter:
            case KeyCode.UpArrow:
            case KeyCode.DownArrow:
                e.preventDefault();
                return false;
        }
    };
    
    /** Reacts to search field keyboard gestures. */
    private onSearchFieldKeyUp = (e: KeyboardEvent) =>
    {
        let currentIndex: number;

        switch(e.keyCode)
        {   
            // Enter = select current  option
            case KeyCode.Enter:
                
                // Quick fix for the Enter issue - Force option change detection
                const selectedOption = this.selectedOption;
                this.selectedOption = null;

                this.selectOption(selectedOption);
                break;
            
            // Down = move selection down by one
            case KeyCode.DownArrow:
                currentIndex = this.options.options.indexOf(this.selectedOption);

                if (currentIndex < this.options.options.length - 1)
                {
                    this.selectedOption = this.options.options[currentIndex + 1];
                }
                else
                {
                    this.selectedOption = this.options.options[0];
                }
                break;
            
            // Up = move selection up by one
            case KeyCode.UpArrow:
                currentIndex = this.options.options.indexOf(this.selectedOption);

                if (currentIndex > 0)
                {
                    this.selectedOption = this.options.options[currentIndex - 1];
                }
                else
                {
                    this.selectedOption = this.options.options[this.options.options.length - 1];
                }
                break;
            
            case KeyCode.Escape:
                this.unFocus();
            break;
        }
    };

    /** Executed when the search field gains focus. */
    private onSearchFieldFocus = (e: Event) => {
        this.focus();
    };

    /** Executed when the search field loses focus. */
    private onSearchFieldBlur = (e: Event) => {
        this.unFocus();
    };

    private onSearchFieldClick = (e: Event) => {
        this.focus();
    };

    /** Triggered via custom event */
    private onBackendSearch = (e: CustomEvent) => {
        this.backendSearch(e.detail);
    };

    private onTriggerItemSelected = (e: CustomEvent) => {
        this.dispatchItemSelectedEvent();
    };

    private onClearEvent = (e:CustomEvent) => {
        this.clear();
    };

    // #endregion

    private clear() {
        this.searchText = '';
        this.selectOption(null);
    }

    private focus() {
        if (this.isSearchFieldFocused) {
            return;
        }

        if (document.activeElement != this.searchField) {
            this.searchField.focus();
        }

        this.value = '';
        this.isSearchMode = true;
        this.showOptions = this.options.options.length > 0;
        this.isSearchFieldFocused = true;
    }

    private unFocus() {
        if (this.isSearchMode && this.showOptions) {
            this.showOptions = false;

            if (this.selectedOption != null) {
                this.selectOption(this.selectedOption);
            }
        }

        this.isSearchFieldFocused = false;
    }

    /** Scrolls the option list to make sure the option is visible, following "classic" <select> logic. */
    private ensureOptionVisible(option: Option) {
        let listTop = this.optionsList.scrollTop;
        let listBottom = listTop + this.optionsList.clientHeight;

        let optionTop = option.element.offsetTop;
        let optionBottom = optionTop + option.element.clientHeight;

        if (optionBottom >= listBottom) {
            this.optionsList.scrollTop = option.element.offsetTop - this.optionsList.clientHeight + option.element.clientHeight;
        } else if (optionTop <= listTop) {
            this.optionsList.scrollTop = option.element.offsetTop;
        }
    }

    /** Removes the is-selected class from all options. */
    private unSelectAllOptions() {
        for(let option of this.options.options) {
            option.element.classList.remove('is-selected');
        }
    }

    /** Selects an option. */
    private selectOption(option: Option, dispatchEvents = true) {
        let forceDispatchEvents = false;

        // Non-empty value and not already set to the value
        if (option != null && this.value == option.value) {
            // Special attribute-based condition to allow triggering the event of first value select
            if (this.element.hasAttribute('data-dispatch-initial-value-select-event')) {
                this.element.removeAttribute('data-dispatch-initial-value-select-event');
    
                forceDispatchEvents = true;
                // continue!
            } else {
                return;
            }
        }

        let previousOption = this.selectedOption;

        if (option != null) {
            this.isSearchMode = false;
        }

        this.selectedOption = option;
        this.element.setAttribute('data-extra', option != null ? JSON.stringify(option.data) : '');

        if ((dispatchEvents && option !== previousOption) || forceDispatchEvents) {
            let changeEvent = new CustomEvent('change');
            this.searchField.dispatchEvent(changeEvent);
            this.element.dispatchEvent(changeEvent);
            this.dispatchItemSelectedEvent(previousOption);
        }
    }

    private dispatchItemSelectedEvent(previousOption?: Option) {
        let selectedEvent = new CustomEvent(LazyModelSelectWidget.Events.ItemSelected, {
            detail: {
                previous: previousOption,
                current: this.selectedOption
            }
        });
        this.element.dispatchEvent(selectedEvent);
    }

    private isInitialColumnMapsCall = true;

    /** Applies extra column data from an option. */
    private applyExtraColumnMaps(option: Option) {
        for(let columnName in this.extraColumnMap) {
            if (!option.data.hasOwnProperty(columnName)) {
                continue;
            }
            
            let selector = this.extraColumnMap[columnName];
            let parentElement = this.element as HTMLElement;
            let targetElement: HTMLElement;

            while (targetElement == null && parentElement != null) {
                targetElement = parentElement.querySelector(selector);
                parentElement = parentElement.parentElement;
            }

            if (targetElement == null) {
                console.warn(`[LazyModelChoiceField] Failed to map "${columnName}" (extra column maps)!`);
            }
            
            this.setExtraColumnMapValue(targetElement, option.data[columnName]);
        }
        
        if (this.isInitialColumnMapsCall) {
            this.isInitialColumnMapsCall = false;
        }
    }

    private setExtraColumnMapValue(element: HTMLElement, value: any) {
        const formPkFieldAttribute = 'data-formset-row-instance-pk';
        let formPkField: HTMLInputElement;
        let parentElement = this.element as HTMLElement;

        while (formPkField == null && parentElement != null) {
            formPkField = parentElement.querySelector(`[${formPkFieldAttribute}]`);
            parentElement = parentElement.parentElement;
        }

        if (formPkField == null) {
            alert(`LazyModelChoiceField of id ${this.element.id} is attempting to set a value for element "<${element.tagName} ... class="${element.className}" ../>. Unable to locate the required PK field with attribute ${formPkFieldAttribute}. If you are unsure of what this means, ask Janne.`);
            return;
        }

        let allowUpdateFormField = true;

        // applyExtraColumnMaps is being called for the first time and the form has a pk - it exists - don't override already user-selected value
        // If the target element is an input field
        if (this.isInitialColumnMapsCall && formPkField.value != null && formPkField.value.length > 0) {
            allowUpdateFormField = false;
        }
        
        const isInputElement = element instanceof HTMLInputElement;
        const isSelectField = element instanceof HTMLSelectElement;

        if (!allowUpdateFormField && (isInputElement || isSelectField)) {
            return;
        } else if (isInputElement) {   // input
            (element as HTMLInputElement).value = value;
        } else if (isSelectField) {  // select
            (element as HTMLSelectElement).value = value;
        } else if (element) {
            // regular elements are allowed to be updated even when the initial value is being set
            element.textContent = value;
        }
    }

    private backendSearch(options: BackendSearchOptions = {}): PromiseLike<void> {
        let {showOptions, useBlankSearch, includeSelected, onlySelected, overrideValue, forceDispatchEvent} = options;

        showOptions = showOptions === false ? false : true;
        useBlankSearch = useBlankSearch === true ? true : false;
        includeSelected = includeSelected === true ? true : false;
        onlySelected = onlySelected == true ? true : false;
        forceDispatchEvent = !!forceDispatchEvent;
        
        // Build additional options
        let additionalFilters = {};
        for(let name in this.additionalFilters) {
            let selector = this.additionalFilters[name];
            additionalFilters[name] = (document.querySelector(selector) as HTMLInputElement).value;
        }
        
        let selectedValueBefore = overrideValue ? overrideValue : this.value;

        return TCApi.call('get_lazy_model_choice_field_options', {
            uuid: this.uuid, 
            search: useBlankSearch || !this.isSearchMode ? '' : this.searchField.value, 
            additional_filters: additionalFilters,
            selected_value: includeSelected ? (overrideValue ? overrideValue : this.value) : null,
            only_selected_value: onlySelected
        }, false).then((result) =>
        {
            this.options = result;
            this.showOptions = showOptions;
            this.isLoading = false;
            
            if (selectedValueBefore != null) {
                let previouslySelected = this.options.options.find(o => o.value == selectedValueBefore);
                if (previouslySelected != null) {
                    this.selectOption(previouslySelected, forceDispatchEvent);
                }
                else {
                    this.selectOption(null, forceDispatchEvent);
                }
            }
        }, (error) => {
            console.error(this.element.getAttribute(Attribute.ErrorMessage));
        });
    }

    /** Hooks event listers to any additional filter sources. */
    private hookAdditionalFilterSourceFields() {
        function listenSourceField(widget: LazyModelSelectWidget, field: HTMLInputElement)
        {
            const updateWidget = () =>
            {
                let isValueEmpty = field.value.length == 0;
                
                // allowEmptyAdditionalFilterValue is disabled by default
                let shouldLock = !widget.allowEmptyAdditionalFilterValue && isValueEmpty;

                /* console.log({
                    shouldLock,
                    field, 
                    'field.value': field.value, 
                    'field.value.length': field.value.length,
                    'allowEmptyAdditionalFilterValue': widget.allowEmptyAdditionalFilterValue,
                }) */

                widget.searchField.disabled = shouldLock;
                widget.changeButton.disabled = shouldLock;

                if (!isValueEmpty)
                {
                    widget.backendSearch({
                        showOptions: false,
                        includeSelected: true
                    });
                }
            };
            
            field.addEventListener('change', (e) => {
                updateWidget();
            });

            updateWidget();
        }
        
        for (let key in this.additionalFilters) {
            let selector = this.additionalFilters[key];
            let sourceField = document.querySelector(selector) as HTMLInputElement;
            listenSourceField(this, sourceField);
        }
    }

    public static isAlreadyRegistered(element: HTMLInputElement) {
        if (!element.hasAttribute('data-is-registered')) {
            element.setAttribute('data-is-registered', '');
            return false;
        }

        return true;
    }

    public static register() {
        for (let element of document.querySelectorAll(this.selector) as NodeListOf<HTMLInputElement>) {
            if (!this.isAlreadyRegistered(element)) {
                new this(element);
            }
        }
    }
}

export enum Attribute
{
    UUID = 'data-uuid',
    Initial = 'data-initial',
    SearchFieldId = 'data-search-field-id',
    ChangeButtonId = 'data-change-button-id',
    OptionsListId = 'data-options-list-id',
    ClearButtonId = 'data-clear-button-id',
    WrapperId = 'data-wrapper-id',
    AdditionalFilters = 'data-additional-filters',
    AutoFetchFirstBatch = 'data-auto-fetch-first-batch',
    AutoSelectOnlyOption = 'data-auto-select-only-option',
    AllowEmptyAdditionalFilterValue = 'data-allow-empty-additional-filter-value',
    ExtraColumnMap = 'data-extra-column-map',
    SearchPlaceholder = 'data-search-placeholder',
    ErrorMessage = 'data-message-error',
    NoResultsMessage = 'data-message-no-results',
    InitialSearchText = 'data-initial-search-text',
}

export interface OptionsData
{
    success: boolean;
    status: string;
    options: Option[];
    is_fallback_option: boolean;
}

export interface Option
{
    data: {[key: string]: string};
    value: string;
    label: string;
    element: HTMLElement;
}

export interface BackendSearchOptions
{
    showOptions?: boolean;
    useBlankSearch?: boolean;
    includeSelected?: boolean;
    onlySelected?: boolean;
    overrideValue?: string;
    forceDispatchEvent?: boolean;
}
