

export default class Url
{
    public static update(url: string, name: string, value: string)
    {
        let newQueryString = '';
        let hashIndex = url.indexOf('#');
        let hash = '';
        if (hashIndex !== -1) {
            hash = url.substring(hashIndex + 1);
            url = url.substring(0, hashIndex);
        }
        let tempArray = url.split('?');
        let baseURL = tempArray[0];
        let queryString = tempArray[1];
        let temp = '';

        if (queryString) 
        {
            let parameters = queryString.split('&');
            for (let i = 0; i < parameters.length; i++)
            {
                // Append existing parameters to the new query string (except the one that is currently being updated)
                if (parameters[i].split('=')[0] != name)
                {
                    newQueryString += `${temp}${parameters[i]}`;
                    temp = '&';
                }
            }
        }
        
        if (hash) {
            hash = `#${hash}`;
        }
        return `${baseURL}?${newQueryString}${temp}${name}=${value}${hash}`;
    }
}