const SOFT_EXPANDED = 'is-soft-expanded';

export default class SoftExpandToggle
{
    public static readonly selector = '.has-soft-expand-toggle';
    public static isRegistered = false;

    private static currentlyExpanded: HTMLElement[] = [];

    private element: HTMLElement;
    private link: HTMLAnchorElement;

    constructor(element: HTMLElement)
    {
        this.element = element;
        this.link = this.element.querySelector('a') as HTMLAnchorElement;

        this.link.addEventListener('click', (e) =>
        {
            this.clearPreviouslyExpanded();
            this.element.classList.toggle(SOFT_EXPANDED);

            if (this.element.classList.contains(SOFT_EXPANDED))
            {
                SoftExpandToggle.currentlyExpanded.push(this.element);
            }
        });
    }

    private clearPreviouslyExpanded()
    {
        for(let expanded of SoftExpandToggle.currentlyExpanded)
        {
            expanded.classList.remove(SOFT_EXPANDED);
        }

        SoftExpandToggle.currentlyExpanded = [];
    }

    public static register()
    {
        if (this.isRegistered)
        {
            return;
        }
        
        this.isRegistered = true;
        for (let element of document.querySelectorAll(this.selector))
        {
            new this(element as HTMLElement);
        }
    }
}