
export default class FileInputWidget
{
    public static readonly selector = '[data-enable-file-input-widget]';
    public static readonly registeredAttribute = 'data-registered-bootstrap-file-input-widget';
    public static isRegistered = false;

    private element: HTMLElement;
    private fileInput: HTMLInputElement;
    private selectedFileInput: HTMLInputElement;

    constructor(element: HTMLElement)
    {
        this.element = element;
        this.fileInput = this.element.querySelector('input[type="file"]');
        this.selectedFileInput = this.element.querySelector('.selected-file');
        this.element.setAttribute(FileInputWidget.registeredAttribute, '');

        this.fileInput.addEventListener('change', (e: Event) =>
        {
            if (this.fileInput.files.length > 0)
            {
                this.selectedFileInput.value = Array.from(this.fileInput.files).map(f => f.name).join(', ');
            }
        });
    }

    public static register()
    {
        FileInputWidget.isRegistered = true;
        
        for (let element of document.querySelectorAll(FileInputWidget.selector))
        {
            if (!element.hasAttribute(FileInputWidget.registeredAttribute)) {
                new FileInputWidget(element as HTMLElement);
            }
        }
    }
}